@font-face {
    font-family: RPJ;
    src: url('/public/assets/fonts/Montserrat/static/Montserrat-Regular.ttf');
}

@font-face {
    font-family: Sidebar;
    src: url('/public/assets/fonts/FontsFree-Net-Montserrat-ExtraLight.ttf');
}

@font-face {
    font-family: Title;
    src: url('/public/assets/fonts/lora/Lora-Regular.ttf');
}

@font-face {
    font-family: Heading;
    src: url('/public/assets/fonts/lora/Lora-Regular.ttf');
}

html {
    font-family: RPJ;
    scroll-behavior: smooth;
}

.title {
    font-family: Title;
}

.heading {
    font-family: Heading;
}

.dashboard_img_Classic {
  background-image: url('../../../public/assets/dashboard_img.png');
  background-size: cover;
  background-position-x: center;
}

.dashboard_img_Gold {
  background-image: url('../../../public/assets/dashboard_img_gold.webp');
  background-size: cover;
  background-position-x: center;
}

.dashboard_img_Silver {
  background-image: url('../../../public/assets/dashboard_img_silver.webp');
  background-size: cover;
  background-position-x: center;
}


.rbc-btn-group{
    font-size: 3vw !important;
}

@media (min-width: 600px) {
    .rbc-btn-group {
        font-size: 14px !important;
    }
}


.popupForm_rooms_guests {
    height: auto;
    width: auto;
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2%;
    border: 1px solid #ccc;
    z-index: 60;
    border-radius: 10px;
  }

  .overlay_rooms_guests {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 50;
  }

  .close_rooms_guests {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
  }

  .popupForm_calendar {
    height: 38rem;
    width: 360px;
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1%;
    border: 1px solid #ccc;
    z-index: 60;
    border-radius: 10px;
  }

  @media only screen and (min-device-width: 1500px) {
    .popupForm_calendar {
      width: 400px;
    }
  }

  .overlay_calendar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 50;
  }

  .close_calendar {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
  }

  input[type="radio"],
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    background-color: #008080;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-position: center;
    outline: none;
    border: 1px solid #008080;
  }

  .download_btn {
    height: 35px;
    width: 90px;
    cursor: pointer;
    border: none;
    background: teal;
    /* border: solid 1px teal; */
    border-radius: 10px;
    overflow: hidden;
    margin-left: 15px;
    /* z-index: -1; */
  }
  
  .download_btn:hover{
    background-color: #008080;
  }
  
  .button_content_download_btn {
    transform: translateY(-33px);
    transition: all 250ms ease-in-out;
  }
  
  .svg_container_download_btn,
  .text_container_download_btn {
    height: 33px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .text_container_download_btn .text_download_btn {
    font-size: 12px;
    color: white;
    font-weight: 600;
    opacity: 1;
    transition: opacity ease-in-out 250ms;
  }
  
  .download_icon_download_btn {
    height: 25px;
    width: 25px;
    fill: white;
    opacity: 0;
    transition: opacity ease-in-out 250ms;
  }
  
  /* Hover state for the button */
  .download_btn:hover .button_content_download_btn {
    transform: translateY(0px);
  }
  
  .download_btn:hover .text_download_btn {
    opacity: 0;
  }
  
  .download_btn:hover .download_icon_download_btn {
    opacity: 1;
  }
  
  .download_btn:focus .download_icon_download_btn {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
  }
  
  @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
  

.popup_form_Delete {
    height: auto;
    width: 80%;
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(45, 45, 45);
    padding: 3%;
    border: 1px solid #ccc;
    z-index: 2;
    border-radius: 10px;
  }

  @media (min-width: 1024px) {
    .popup_form_Delete {
      width: 30%;
      left: 55%;
      padding: 1%;
    }
  }

  .overlay_Delete {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
  }
  .close_Delete {
    position: absolute;
    top: 2px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
    color: white;
  }
