html {
  scroll-behavior: smooth;
  background-color: #f8f6f6;
}

.underline {
  text-decoration: underline;
  display: inline-block;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  z-index: 0;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 94%);
  }

  50% {
    background-color: hsl(0, 0%, 98%);
  }

  100% {
    background-color: hsl(200, 20%, 100%);
  }
}

.react-multiple-carousel__arrow {
  position: absolute !important;
  outline: 0 !important;
  transition: all 0.5s !important;
  border-radius: 200px !important;
  z-index: 1 !important;
  border: 0 !important;
  background: rgba(255, 255, 255, 0) !important;
  min-width: 28px !important;
  min-height: 10px !important;
  opacity: 1 !important;
  cursor: pointer !important;
  top: 1% !important;
}

@media only screen and (min-device-width: 900px) {
  .react-multiple-carousel__arrow {
    top: 5% !important;
    margin-left: 53% !important;
  }
}

.react-multiple-carousel__arrow::before {
  font-size: 10px !important;
  color: black !important;
}

@font-face {
  font-family: RPJ;
  src: url("/public/assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Sidebar;
  src: url("/public/assets/fonts/FontsFree-Net-Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: Title;
  src: url("/public/assets/fonts/lora/Lora-Regular.ttf");
}

@font-face {
  font-family: Heading;
  src: url("/public/assets/fonts/lora/Lora-Regular.ttf");
}

html {
  font-family: RPJ;
}

.title {
  font-family: Title;
}

.heading {
  font-family: Heading;
}

.food_shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 3.2px;
}

.restroCafe_shadow {
  box-shadow: rgba(17, 17, 26, 0.5) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.services_card {
  /* background-color: yellow; */
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.popupForm_rooms_guests {
  height: auto;
  width: auto;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2%;
  border: 1px solid #ccc;
  z-index: 60;
  border-radius: 10px;
}

.overlay_rooms_guests {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.close_rooms_guests {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

.popupForm_calendar {
  height: 38rem;
  width: 360px;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1%;
  border: 1px solid #ccc;
  z-index: 60;
  border-radius: 10px;
}

@media only screen and (min-device-width: 1500px) {
  .popupForm_calendar {
    width: 400px;
  }
}

.overlay_calendar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.close_calendar {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

.book_now_mainContainer:after {
  content: "";
  display: table;
  clear: both;
}

.column1_mainContainer {
  float: left;
  height: 100%;
  width: 25%;
  background-color: teal;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
}

.column2_mainContainer {
  float: left;
  height: 100%;
  width: 75%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
}

.book_now_section:after {
  content: "";
  display: table;
  clear: both;
}

.book_now_section {
  width: 100%;
  /* padding: 1%; */
}

.column1_book_now {
  float: left;
  height: 100%;
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* background-color: rebeccapurple; */
}

.group:hover .line-container::after {
  width: 100%;
}

.line-container {
  position: relative;
  /* font-size: 24px; */
  /* padding: 10px; */
  cursor: pointer;
  color: #333;
  width: 35%;
}

@media only screen and (min-device-width: 601px) and (max-device-width: 900px) {
  .line-container {
    width: 55%;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
  .line-container {
    width: 35%;
  }
}

@media only screen and (min-device-width: 1500px) {
  .line-container {
    width: 33%;
  }
}

.line-container::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #333;
  transition: width 0.4s ease;
}

.line-container:hover::after {
  width: 100%;
}

.button {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: transparent;
  color: white;
  border: 2px solid lightgray;
  border-radius: 10px;
  font-weight: 600;
  padding: 0.5rem 1rem;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.button__icon-wrapper {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: white;
  /* background-color: #fff; */
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.button:hover {
  background-color: #008080;
  color: #fff;
  border-color: #008080;
}

.button:hover .button__icon-wrapper {
  color: #008080;
  background-color: #fff;
}

.button__icon-svg--copy {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button:hover .button__icon-svg:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.button:hover .button__icon-svg--copy {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

.button_BookNow {
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: transparent;
  color: black;
  border: 2px solid lightgray;
  border-radius: 10px;
  /* font-weight: 600; */
  padding: 0.2rem;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.button__icon-wrapper_BookNow {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  position: relative;
  color: black;
  /* background-color: #fff; */
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.button_BookNow:hover {
  background-color: #008080;
  color: #fff;
  border-color: #008080;
}

.button_BookNow:hover .button__icon-wrapper_BookNow {
  color: #008080;
  background-color: #fff;
}

.button__icon-svg--copy_BookNow {
  position: absolute;
  transform: translate(-150%, 150%);
}

.button_BookNow:hover .button__icon-svg_BookNow:first-child {
  transition: transform 0.3s ease-in-out;
  transform: translate(150%, -150%);
}

.button_BookNow:hover .button__icon-svg--copy_BookNow {
  transition: transform 0.3s ease-in-out 0.1s;
  transform: translate(0);
}

/* Event Gallery Page Css starts from here */
.event_btn {
  border: none;
  outline: none;
}

.active_btn {
  text-decoration: underline;
  text-decoration-color: white;
  text-underline-offset: 0.5em;
  text-decoration-thickness: 0.5px;
}

.event_btn:hover {
  color: rgba(255, 255, 255, 0.6);
}

/* Event Gallery Page Css ends here */

/* Rooms page css starts from here */
.calendar_bg {
  position: absolute;
  z-index: 50;
}

.booking_section:after {
  content: "";
  display: table;
  clear: both;
}

.column1_booking {
  float: left;
  height: 100%;
  width: 50%;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column2_booking {
  float: left;
  height: 100%;
  width: 40%;
  margin-right: 1%;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column3_booking {
  float: left;
  height: 100%;
  width: 8%;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Rooms page css ends  here */

/* Login page css starts from here */

.cursor-pointer {
  cursor: pointer;
}

.eye-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.signIn_btn {
  display: inline-block;
  border-radius: 10vw;
  background-color: #008080;
  border: none;
  color: #ffffff;
  text-align: center;
  padding: 1%;
  width: 36%;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-weight: 600;
}

.signIn_btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.signIn_btn span:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

.signIn_btn:hover span {
  padding-right: 15px;
}

.signIn_btn:hover span:after {
  opacity: 1;
  right: 0;
}

/* Login page css ends here */

/* Dashboard page css starts from here */

/* Dashboard page css ends here */

/* Stay preferences page css starts from here */
.toggle_btn {
  display: inline;
  margin-right: 3%;
  border: none;
  outline: none;
  background-color: white;
  color: gray;
}

.toggle_btn:hover {
  text-decoration: underline;
  text-decoration-color: #008080;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  color: black;
}

.active_toggle_btn {
  text-decoration: underline;
  text-decoration-color: #008080;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  color: black;
}

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: #008080;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  outline: none;
  border: 1px solid #008080;
}

.print_radioBtn {
  appearance: auto !important;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  width: auto;
  height: auto;
  border: none;
  border-radius: 0;
  background: none;
}

/* Stay preferences page css ends here */

/* Your Information page css starts from here */
.select_style {
  appearance: none;
}

/* Your Information page css ends here */

/* Date picker styling */
.flatpickr-monthDropdown-months {
  appearance: none !important;
}

/* Hot & Crusty page css starts from here */

.book {
  display: flex;
  margin: auto;
  width: 50%;
  /*1* let pointer event go trough pages of lower Z than .book */
  pointer-events: none;
  transform-style: preserve-3d;
  transition: translate 1s;
  translate: calc(min(var(--c), 1) * 50%) 0%;
  /* DEMO ONLY: incline on the X axis for pages preview */
  rotate: 1 0 0 30deg;
}

.page {
  --thickness: 5;
  /* PS: Don't go below thickness 0.4 or the pages might transpare */
  flex: none;
  display: flex;
  width: 100%;
  /*1* allow pointer events on pages */
  pointer-events: all;
  user-select: none;
  transform-style: preserve-3d;
  border: 1px solid #0008;
  border-radius: 20px;
  transform-origin: left center;
  transition: transform 1s,
    rotate 1s ease-in
      calc((min(var(--i), var(--c)) - max(var(--i), var(--c))) * 50ms);
  translate: calc(var(--i) * -100%) 0px 0px;
  transform: translateZ(
    calc((var(--c) - var(--i) - 0.5) * calc(var(--thickness) * 1px))
  );
  rotate: 0 1 0 calc(clamp(0, var(--c) - var(--i), 1) * -180deg);
}

.page img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.front,
.back {
  flex: none;
  width: 100%;
  padding: 2%;
  backface-visibility: hidden;
  background-color: #fff;
  /* Fix backface visibility Firefox: */
  translate: 0px;
  border-radius: 20px;
}

.back {
  background-image: linear-gradient(to right, #fff 80%, #ddd 100%);
  translate: -100% 0;
  rotate: 0 1 0 180deg;
}

/* Hot & Crusty page css ends here */

/* Selected room page css ends here */

.slick-slider .slick-arrow.slick-prev:before,
.slick-slider .slick-arrow.slick-next:before {
  color: lightgray;
}

/* Selected room page css ends here */

.popup_form_passwordChanged {
  height: auto;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1%;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.overlay_passwordChanged {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

.close_passwordChanged {
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

/* Edit Career Application css start */

/* Default Layout - Landscape (for Preview in Browser) */

.no-print {
  display: none; /* Hide the Print Button */
}

/* Edit Career Application css end */
