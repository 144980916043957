.menubar1,
.menubar2,
.menubar3 {
    width: 25px;
    height: 2px;
    background-color: black;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 5vw;
    z-index: 80;
}

.changebar .menubar1 {
    transform: translate(0, 5px) rotate(-45deg);
    z-index: 80;
}

.changebar .menubar2 {
    opacity: 0;
}

.changebar .menubar3 {
    transform: translate(0, -11px) rotate(45deg);
    z-index: 80;
}

.changebar {
    background-color: transparent;
    color: black;
    outline: none;
    z-index: 80;
}

.menu_bg {
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    transition: 0.8s ease-in-out;
    position: fixed;
    z-index: 50;
    overflow: auto;
    max-height: 100vh;
    position: fixed;
}

.overlay_money_menu_WebHeader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
}

.popup_money_menu_WebHeader {
    /* height: auto;
    width: auto;
    display: block;
    position: absolute;
    left: 55%;
    top: -45%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 1%;
    z-index: 2;
    border-radius: 0.8vw;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    height: auto;
    width: auto;
    display: block;
    position: absolute;
    left: 51%;
    top: -65%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 10px;
    z-index: 2;
    border-radius: 5px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.overlay_language_menu_WebHeader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
}

.popup_language_menu_WebHeader {
    /* height: auto;
    width: auto;
    display: block;
    position: absolute;
    left: 63%;
    top: -160%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 1%;
    z-index: 2;
    border-radius: 0.8vw;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    height: auto;
    width: auto;
    display: block;
    position: absolute;
    left: 65%;
    top: -180%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 10px;
    z-index: 2;
    border-radius: 5px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
}

.popup_form_UserDetail {
    height: auto;
    width: 55%;
    display: block;
    position: absolute;
    /* left: 40%; */
    right: 3%;
    top: 55px;
    padding: 1em;
    /* transform: translate(-50%, -50%); */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media only screen and (min-device-width: 601px) and (max-device-width: 900px) {
    .popup_form_UserDetail{
        width: 45%;
        /* left: 53%; */
        right: 5%;
    }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
    .popup_form_UserDetail{
        width: 260px;
        /* left: 69%; */
        right: 20px;
    }
}

@media only screen and (min-device-width: 1500px) {
    .popup_form_UserDetail{
        width: 350px;
        top: 63px;
        /* left: 75%; */
        right: 12px;
    }
}

.overlay_UserDetail {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
}

.close_UserDetail {
    position: absolute;
    top: 2px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
}
